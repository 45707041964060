/* Button styling */
.btn-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    gap: 5px;
}

.reset-btn {
    border: none;
    margin: 0;
    padding: 0;
    width: auto;
    overflow: visible;
    background: transparent;
    color: inherit;
    font: inherit;
    line-height: normal;
    -webkit-font-smoothing: inherit;
    -moz-osx-font-smoothing: inherit;
    -webkit-appearance: none;
}

.btn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;

    padding: 0.375rem 0.75rem;

    background-color: var(--color-dark);
    color: var(--color-text-light);

    text-align: center;
    line-height: 1.5;
    font-size: 16px;
    font-weight: bold;
    white-space: nowrap;

    border: 1px solid transparent;
    border-radius: var(--border-radius);

    transition: all 0.15s ease-in-out;
    cursor: pointer;
    user-select: none;
}

.btn:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.btn:not(:disabled):hover {
    box-shadow: var(--box-shadow);
}

.btn:not(:disabled):focus {
    box-shadow: 0 0 0 0.2rem var(--color-accent-translucent);
}

.btn-primary {
    color: var(--color-text-light);
    background-color: var(--color-primary);
    border-color: var(--color-primary);
}

.btn-primary:not(:disabled):hover, .btn-primary:not(:disabled):focus {
    background-color: var(--color-primary-highlight);
    border-color: var(--color-primary-highlight);
}

.btn-secondary {
    color: var(--color-text-light);
    background-color: var(--color-secondary);
    border-color: var(--color-secondary);
}

.btn-secondary:not(:disabled):hover, .btn-secondary:not(:disabled):focus {
    background-color: var(--color-secondary-highlight);
    border-color: var(--color-secondary-highlight);
}

.btn-success {
    color: var(--color-text-light);
    background-color: var(--color-success);
    border-color: var(--color-success);
}

.btn-success:not(:disabled):hover, .btn-success:not(:disabled):focus {
    background-color: var(--color-success-highlight);
    border-color: var(--color-success-highlight);
}

.btn-danger {
    color: var(--color-text-light);
    background-color: var(--color-danger);
    border-color: var(--color-danger);
}

.btn-danger:not(:disabled):hover, .btn-danger:not(:disabled):focus {
    background-color: var(--color-danger-highlight);
    border-color: var(--color-danger-highlight);
}

.btn-outline {
    background: inherit;
    border: 3px solid var(--color-text);

    color: var(--color-text);
}

.btn-minimal {
    background: none;
    border: none;

    color: var(--color-text);
}

.btn-outline:not(:disabled):hover, .btn-outline:not(:disabled):focus, .btn-minimal:not(:disabled):hover, .btn-minimal:not(:disabled):focus {
    background-color: rgba(0, 0, 0, 0.02);
}

.btn-outline-primary {
    color: var(--color-primary);
    border-color: var(--color-primary);
}

.btn-outline-primary:not(:disabled):hover, .btn-outline-primary:not(:disabled):focus {
    border-color: var(--color-primary-highlight);
}

.btn-outline-secondary {
    color: var(--color-secondary);
    border-color: var(--color-secondary);
}

.btn-outline-secondary:not(:disabled):hover, .btn-outline-secondary:not(:disabled):focus {
    border-color: var(--color-secondary-highlight);
}

.btn-outline-danger {
    color: var(--color-danger);
    border-color: var(--color-danger);
}

.btn-outline-danger:not(:disabled):hover, .btn-outline-danger:not(:disabled):focus {
    border-color: var(--color-danger-highlight);
}

.btn-minimal-primary {
    color: var(--color-primary);
}

.btn-minimal-secondary {
    color: var(--color-secondary);
}


