form.form-inline {
    display: grid;
    grid-template-columns: 1fr auto;

    gap: 5px;
}

.form-group {
    position: relative;
}

form:not(.form-inline) .form-group:not(.no-margin) {
    margin-bottom: 1rem;
}

.form-group .form-control-header {
    display: block;
}

.form-group:not(.form-group-inline):not(.no-margin) .form-control-header {
    margin-bottom: 0.5rem;
}

.form-group .form-control-header.has-button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 5px;
}

.form-group .form-control-header.has-button label {
    flex: 1;
}

.form-group .form-control-header .form-control-required {
    color: var(--color-danger);
}

.form-group.form-group-inline {
    display: flex;
    align-items: center;
    gap: 5px;
}

.form-group .form-control {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: var(--color-text);
    background-color: var(--color-background-accent);
    background-clip: padding-box;
    border: 1px solid var(--color-dark);
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.form-group.form-group-inline .form-control {
    width: auto;
}

.form-group .form-control:focus {
    border-color: var(--color-accent);
    box-shadow: 0 0 0 0.2rem var(--color-accent-translucent);
}

.form-group .form-control:disabled {
    background-color: var(--color-background);
    opacity: 0.5;
    cursor: not-allowed;
}

.form-group.has-error .form-control {
    background-color: var(--color-danger-light);

    border-color: var(--color-danger);
    box-shadow: 0 0 0 0.2rem var(--color-danger-light);
}

.form-group textarea.form-control {
    resize: none;
}

.form-buttons {
    display: flex;
    align-items: center;
    gap: 5px;
}

.form-buttons > button.submit-button {
    flex: 1;
}
