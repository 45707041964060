.approve-users {
    display: grid;
    grid-template-columns: repeat(5, max-content) 1fr;
    align-items: center;

    overflow-x: auto;
}

@media (max-width: 1249px) {
    .approve-users {
        max-height: calc(100vh - 200px);
        min-height: 200px;
        overflow-y: auto;
    }
}

.approve-users > div {
    padding: 0 15px;
}

.approve-users > div.approve-users-header {
    font-weight: bold;
}

.approve-users > div:not(.approve-users-header) {
    display: flex;
    align-items: center;

    min-height: 50px;
}

.approve-users > div.approve-users-button {
    justify-content: center;
}

.approve-users .approve-users-even {
    background-color: var(--color-light);
}
