.spinnerWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}

.spinnerWrapper:not(.spinner-absolute) {
    width: 100%;
}

.spinnerWrapper.spinner-absolute {
    position: absolute;
    top: -2px;
    right: -2px;
    left: -2px;
    bottom: -2px;
    z-index: 2000;
}

.spinnerWrapper.spinner-overlay {
    background-color: rgba(0, 0, 0, .5);
}

.spinner {
    display: block;
    height: 64px;
    width: 64px;
    cursor: progress;
    padding: 16px;
    background-color: rgba(0, 0, 0, .5);
    border-radius: 100%;
    z-index: 2000;
}

.spinner > svg {
    display: block;
    position: absolute;
    width: 32px;
    height: 32px;
    line-height: 1;
    -webkit-animation: u-animate-spin 1s infinite linear;
    animation: u-animate-spin 1s infinite linear;
}

@-webkit-keyframes u-animate-spin {
    0% {
        -webkit-transform: rotate(0)
    }

    100% {
        -webkit-transform: rotate(359deg)
    }
}

@keyframes u-animate-spin {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }

    100% {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg)
    }
}
