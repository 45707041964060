.overview-item {
    margin-bottom: 15px;
    cursor: pointer;

    transition: box-shadow 0.3s ease-in-out;
}

.overview-item:last-child {
    margin-bottom: 0;
}

.overview-item:hover {
    box-shadow: none;
}

.overview-item header h2 {
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;

    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
}

.overview-item main {
    margin: 15px 0;
}

.overview-item main .description {
    line-height: 1.5em;

    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;

    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
}

.overview-item footer .pills {
    display: flex;
    align-items: center;
    gap: 5px;
    flex-wrap: wrap;
}

@media (max-width: 991px) {
    .overview-item footer .pills {
        margin-bottom: 5px;
    }
}

@media (min-width: 992px) {
    .overview-item header, .overview-item footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 15px;
    }

    .overview-item header .last-updated, .overview-item footer .user {
        white-space: nowrap;
    }

    .overview-item header .last-updated {
        align-self: flex-start;
    }

    .overview-item footer .user {
        align-self: flex-end;
    }

    .overview-item main .description {
        min-height: 3em;
    }
}

.selected-issue {
    animation-name: color;
    animation-duration: 1s;
    animation-iteration-count: 4;
}

@keyframes color {
    0% {
        background-color: var(--color-background-accent);
        box-shadow: var(--box-shadow);
    }
    40% {
        background-color: var(--color-background);
        box-shadow: var(--box-shadow-lifted);
    }
    60% {
        background-color: var(--color-background);
        box-shadow: var(--box-shadow-lifted);
    }
    100% {
        background-color: var(--color-background-accent);
        box-shadow: var(--box-shadow);
    }
}
