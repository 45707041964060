.problem-issue-form-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: max-content 1fr auto auto;
    grid-gap: 15px;

    margin-bottom: 15px;
}

.problem-issue-form-container > .problem-issue-form-ids {
    grid-row: 1;
    grid-column: 1;
}

.problem-issue-form-container > .problem-issue-form-general {
    grid-row: 2;
    grid-column: 1;
}

.problem-issue-form-container > .problem-issue-form-api-values {
    grid-row: 1 / 3;
    grid-column: 2;
}

.problem-issue-form-container > .problem-issue-form-title {
    grid-row: 3;
    grid-column: 1 / 3;
}

.problem-issue-form-container > .problem-issue-form-descriptions {
    grid-row: 4;
    grid-column: 1 / 3;
}

@media (max-width: 767px) {
    .problem-issue-form-container {
        grid-template-columns: 1fr;
    }

    .problem-issue-form-container > .problem-issue-form-ids {
        grid-row: 1;
        grid-column: 1;
    }

    .problem-issue-form-container > .problem-issue-form-general {
        grid-row: 2;
        grid-column: 1;
    }

    .problem-issue-form-container > .problem-issue-form-api-values {
        grid-row: 3;
        grid-column: 1;
    }

    .problem-issue-form-container > .problem-issue-form-title {
        grid-row: 4;
        grid-column: 1;
    }

    .problem-issue-form-container > .problem-issue-form-descriptions {
        grid-row: 5;
        grid-column: 1;
    }
}
