.collapse-panel.collapse-panel-collapsable header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    cursor: pointer;
}

.collapse-panel header .collapse-panel-title {
    font-weight: bold;
}

.collapse-panel header .collapse-panel-toggle .icon {
    transform: rotate(0deg);
}

.collapse-panel.collapse-panel-opened header .collapse-panel-toggle .icon {
    transform: rotate(180deg);
    transition: transform 0.3s ease-in-out;
}

.collapse-panel main {
    padding: 0 5px;
}

.collapse-panel hr {
    margin-top: 0;
}

.collapse-panel main, .collapse-panel hr.collapse-panel-bottom-separator {
    display: none;
    overflow: hidden;
}

.collapse-panel.collapse-panel-opened main, .collapse-panel.collapse-panel-opened hr.collapse-panel-bottom-separator {
    display: block;
    animation: slide-in-panel 0.5s ease-in-out;
}

/* Slide-in animation */
@keyframes slide-in-panel {
    0% {
        display: none;
        margin: 0;
        max-height: 0;
        opacity: 0;
        padding: 0;
    }
    0.1% {
        display: block;
    }
    50% {
        padding: 0 5px;
    }
    100% {
        max-height: 100vh;
    }
}
