/* === Default Layout === */
#default-layout {
    margin: auto;
    padding: 15px;

    max-width: 1500px;
}

/* === Header === */
#default-layout-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    margin-bottom: 15px;
}

#default-layout-header > .default-layout-header-title {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 15px;
}

#default-layout-header > .default-layout-header-title h1 {
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;

    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
}

@media (max-width: 767px) {
    #default-layout-header {
        align-items: flex-start;
        flex-direction: column;
    }

    #default-layout-header > .default-layout-header-title {
        order: 2;
    }

    #default-layout-header > .default-layout-header-buttons {
        order: 1;
        align-self: flex-end;
        flex-wrap: wrap;
    }
}

/* === Main === */
#default-layout-main {
    display: grid;

    grid-template-columns: 3fr 1fr;
    gap: 10px 30px;
}

#default-layout-main.no-headers {
    gap: 0 30px;
}

@media (max-width: 991px) {
    #default-layout-main > * {
        grid-column: span 2;
    }

    #default-layout-main .default-layout-content-header {
        order: 3;
    }

    #default-layout-main .default-layout-aside-header {
        order: 1;
    }

    #default-layout-main .default-layout-content {
        order: 4;
    }

    #default-layout-main .default-layout-aside {
        order: 2;
    }
}

@media (min-width: 992px) {
    #default-layout-main .default-layout-content-header {
        grid-row: 1;
        grid-column: 1 / 3;
    }

    #default-layout-main.has-aside .default-layout-content-header.has-aside-header {
        grid-column: 1;
    }

    #default-layout-main.has-aside .default-layout-aside-header {
        grid-row: 1;
        grid-column: 2;
    }

    #default-layout-main .default-layout-content {
        grid-row: 2;
        grid-column: 1 / 3;
    }

    #default-layout-main.has-aside .default-layout-content {
        grid-column: 1;
    }

    #default-layout-main .default-layout-aside {
        grid-row: 2;
        grid-column: 2;
    }
}

#default-layout-main .default-layout-content {
    width: 100%;

    justify-self: center;
}

@media (min-width: 992px) {
    #default-layout-main .default-layout-aside {
        width: 100%;
        height: fit-content;
    }

    #default-layout-main .default-layout-aside.aside-sticky {
        position: sticky;
        top: 15px;
    }
}

