.icon {
    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 20px;

    max-height: 30px;
}

.icon svg {
    width: 100%;
    max-height: 30px;

    fill: currentColor;
}
