.card.errors-card {
    background-color: var(--color-danger-light);
    color: var(--color-danger-dark);

    margin-bottom: 15px;
}

.card.errors-card main.has-header {
    padding: 0 15px;
}

.card.errors-card p, .card.errors-card ul {
    margin: 0;
}

.card.errors-card p {
    font-weight: bold;
}
