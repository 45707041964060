.scroll-button {
    position: fixed;
    bottom: 10px;
    right: 10px;
    width: 50px;
    height: 50px;

    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 50%;
    background-color: var(--color-background-accent);
    box-shadow: var(--box-shadow-lifted);

    cursor: pointer;
    transition: all 0.2s ease-in-out;
    z-index: 999;
}

.scroll-button.hidden {
    opacity: 0;
    pointer-events: none;
    border-radius: 25%;

    transition-duration: 0s;
}

.scroll-button:hover {
    box-shadow: var(--box-shadow);
}
