.activity {
    width: 80%;

    padding: 15px;
    margin-bottom: 15px;
    align-self: flex-start;
}

@media (max-width: 499px) {
    .activity {
        width: 100%;
    }
}

.activity.own-activity {
    align-self: flex-end;
    background-color: var(--color-accent-light);
}

.activity > header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 5px;
}

.activity .activity-info {
    font-weight: bold;
}

.activity .activity-origin {
    font-size: 0.8rem;
    font-style: italic;
}

.activity .activity-content {
    margin: 0;
    white-space: pre-wrap;
    overflow-wrap: break-word;
}

.activity.activity-update .activity-content {
    font-style: italic;
}

.activity.has-trash {
    display: grid;
    grid-template-columns: 1fr auto;
    grid-template-rows: auto;
    grid-column-gap: 15px;
    padding: 0;
}

.activity.has-trash > header {
    grid-column: 1 / 2;
    margin-top: 15px;
    margin-left: 15px;
}

.activity.has-trash > main {
    grid-column: 1 / 2;
    margin-bottom: 15px;
    margin-left: 15px;
}

.activity.has-trash > footer {
    grid-column: 2 / 3;
    grid-row: 1 / 3;
    width: 0;
    padding: 0;

    display: flex;
    align-items: center;

    background-color: var(--color-danger-light);

    color: var(--color-danger);

    border-radius: 0 var(--border-radius) var(--border-radius) 0;
    cursor: pointer;

    transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out, width 0.2s ease-in-out, padding 0.2s ease-in-out;
}

.activity.has-trash:hover > footer {
    width: unset;
    padding: 0 10px;
}

.activity.has-trash > footer:hover {
    background-color: var(--color-danger);
    color: var(--color-danger-light);
}


