#layout-container {
    display: flex;
    align-items: center;
    height: 100%;

    background-color: var(--color-background-accent);
}

#layout-container .layout-image {
    height: 100%;
    width: 40%;

    background-image: url(/public/images/side-bg.jpg);
    background-position: center;
    background-size: cover;
}

#layout-container .layout-content {
    height: 100%;
    width: 60%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
}

#layout-container .layout-content-container {
    width: 400px;
}

@media (max-width: 450px) {
    #layout-container .layout-content-container {
        min-width: unset;
        width: 90%;
    }
}

#layout-container .layout-content-container > header {

}

#layout-container .layout-content-container > header > img {
    max-width: 150px;
    margin-bottom: 20px;
}

#layout-container .layout-content-container > header > h1 {
    margin: 0;
    text-align: center;
}

#layout-container .layout-content-container > main {
    padding: 0 40px;
}

@media (max-width: 768px) {
    #layout-container .layout-image {
        display: none;
    }

    #layout-container .layout-content {
        width: 100%;
    }
}
