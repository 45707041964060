.pill {
    display: flex;
    gap: 5px;
    align-items: center;

    width: fit-content;
    padding: 5px 15px;
    border-radius: 8px;

    color: var(--color-text-light);

    border: 1px solid transparent;
}

@media (max-width: 399px) {
    .pill {
        word-break: break-all;
    }
}

.pill-bold {
    font-weight: bold;
}

.pill-info {
    background-color: var(--color-info);
    border-color: var(--color-info);
}

.pill-success {
    background-color: var(--color-success);
    border-color: var(--color-success);
}

.pill-warning {
    background-color: var(--color-warning);
    border-color: var(--color-warning);
}

.pill-danger {
    background-color: var(--color-danger);
    border-color: var(--color-danger);
}

.pill-outline, .pill-minimal {
    color: var(--color-text);
}

.pill-outline {
    background-color: transparent;
    border-color: var(--color-dark);
}

.pill-outline-info {
    color: var(--color-info);
    border-color: var(--color-info);
}

.pill-minimal {
    padding: 5px 0;
}
