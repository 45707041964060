/* Colors and Variables */
:root {
    --color-primary: #54C5E0;
    --color-primary-highlight: #7fd4e8;

    --color-secondary: #4F4F61;
    --color-secondary-highlight: #66667d;

    --color-accent: #7B42FA;
    --color-accent-highlight: #9e74fb;
    --color-accent-translucent: rgba(123, 66, 250, 0.5);
    --color-accent-light: #f8f5fe;

    --color-light: #F5F5F5;
    --color-dark: #242020;

    --color-success: #4CAF50;
    --color-success-highlight: #6bd96b;
    --color-success-light: #eafaea;
    --color-success-dark: #278701;

    --color-info: #2196F3;
    --color-info-highlight: #6ec6ff;

    --color-warning: #FF9800;
    --color-warniong-highlight: #ffbd69;

    --color-danger: #F44336;
    --color-danger-highlight: #ff6f6f;
    --color-danger-light: #ffeaea;
    --color-danger-dark: #c62828;

    --color-background: var(--color-light);
    --color-background-accent: #FFFFFF;

    --color-text: var(--color-dark);
    --color-text-accent: #7b7979;
    --color-text-light: var(--color-light);

    --border-radius: 8px;

    --box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 1px 3px 0 rgba(0, 0, 0, 0.1);
    --box-shadow-lifted: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 10px 30px 0 rgba(0, 0, 0, 0.1);
}

/* Reset */
h1, h2, h3, h4, h5, h6 {
    margin: 0;
    padding: 0;
}

/* Basic Styling */
html, body, #root {
    height: 100%;
    width: 100%;
}

body {
    margin: 0;
    background-color: var(--color-background);

    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    line-height: 1.5;
    color: var(--color-text);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

* {
    box-sizing: border-box;
}

/* Links */
a {
    text-decoration: none;
}

a:not(.btn) {
    color: var(--color-accent);
}

a:not(.btn):hover, a:not(.btn):focus, a:not(.btn):active {
    color: var(--color-accent-highlight);
}
