.loadingPage {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;

    display: flex;
    justify-content: center;
    align-items: center;

    z-index: 9999;
}

.loadingPage.overlay {
    background-color: rgba(0, 0, 0, .5);
    z-index: 9998;
}
