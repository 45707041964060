.filter-container {
    display: flex;
    align-items: center;
}

.filter-container:not(.quick-filters) {
    gap: 5px;
    flex-wrap: wrap;

    margin-bottom: 15px;
}
