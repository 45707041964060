.success-message {
    position: fixed;
    top: 20px;
    right: 20px;

    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;

    padding: 5px 15px;

    background-color: var(--color-success-dark);

    color: var(--color-text-light);

    border-radius: var(--border-radius);
    box-shadow: var(--box-shadow-lifted);

    z-index: 999;
}

.success-message-icon .icon {
    width: 30px;
    height: 30px;
}

.success-message-content p {
    margin: 0;
}

.success-message-close {
    position: absolute;
    top: -13px;
    right: -13px;

    display: flex;
    align-items: center;
    justify-content: center;

    padding: 3px;

    background-color: var(--color-secondary);

    border-radius: 50%;

    box-shadow: var(--box-shadow);

    cursor: pointer;
}

.success-message-close:hover {
    background-color: var(--color-secondary-highlight);
}

.success-message-close .icon {
    width: 20px;
    height: 20px;
}
