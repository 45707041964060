.quick-filter {
    padding: 0 10px;

    color: var(--color-text-accent);
    border-bottom: 3px solid transparent;

    cursor: pointer;
    transition: color 0.3s ease-in-out, border-bottom 0.3s ease-in-out;
}

.quick-filter.active, .quick-filter:not(.disabled):hover {
    color: var(--color-text);
    border-bottom: 3px solid var(--color-primary);
}

.quick-filter.disabled {
    opacity: 0.4;
}
