.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    z-index: 1099;

    background-color: rgba(0, 0, 0, 0.75);
}

.popup-overlay:not(.processing) {
    cursor: pointer;
}

section.popup {
    position: fixed;
    padding: 5px 15px;

    background-color: var(--color-background);

    border-radius: var(--border-radius);
    overflow-y: auto;
    z-index: 1100;
}

section.popup.fullscreen {
    top: 5%;
    left: 5%;
    right: 0;
    bottom: 0;

    width: 90%;
    height: 90%;
}

section.popup:not(.fullscreen) {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

@media (max-width: 767px) {
    section.popup:not(.fullscreen) {
        top: 50%;
        left: 5%;

        width: 90%;

        transform: translate(0, -50%);
    }
}

section.popup > header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 5px;

    margin-bottom: 15px;
}

section.popup main {
}
