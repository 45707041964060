@media (min-width: 500px) and (max-width: 991px) {
    .details-information {
        display: flex;
        gap: 60px;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .details-information-dates {
        display: flex;
        gap: 60px;
    }
}
