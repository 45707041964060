.problem-issues-filters {
    display: grid;
    grid-template-columns: 1fr auto;

    gap: 5px;

    margin-bottom: 15px;
}

@media (max-width: 991px) {
    .problem-issues-filters {
        grid-template-columns: 1fr;
    }

    .problem-issues-search {
        order: 2;
    }

    .problem-issues-buttons {
        order: 1;
    }
}

@media (min-width: 992px) {
    .problem-issues-filters {
        position: sticky;
        top: 15px;
    }

    .problem-issues-search {
        max-width: 75%;
    }
}

.problem-issues-buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    gap: 5px;
}

.no-issues {
    font-style: italic;
}

.loading-more-issues-indicator, .load-more-issues-button {
    margin-top: 15px;
}

.load-more-issues-button {
    display: flex;
    justify-content: center;
}

.problem-issues-errors {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    margin-top: 15px;
}
