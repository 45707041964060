.issue-description {
    margin-bottom: 15px;

    white-space: pre-wrap;
    overflow-wrap: break-word;
    word-break: break-word;
}

.issue-description header {
    text-align: right;
    margin-top: -5px;

    background-color: var(--color-background-accent);

    font-size: 0.8rem;
    font-style: italic;
}
