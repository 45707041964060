.countdown {
    position: relative;
    
    height: 50px;
    width: 50px;
}

.countdown svg circle {
    fill: none;

    stroke: var(--color-text-light);
    stroke-width: 7;
    stroke-dasharray: 314.159;
    stroke-dashoffset: 0;

    transform: scaleX(-1) rotate(-90deg);
    transform-origin: 50% 50%;

    animation: dash 0ms linear forwards;
}

.countdown .countdown-content {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    top: 0;
    
    display: flex;
    align-items: center;
    justify-content: center;
}

@keyframes dash {
    to {
        stroke-dashoffset: 314.159;
    }
}
