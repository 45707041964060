#site-nav {
    z-index: 1000;
    overflow: hidden;
    padding: 15px 0;
}

#site-nav .nav-wrapper {
    grid-area: 1/1/2/2;
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    grid-template-rows: 1fr;
}

#site-nav .nav-wrapper .site-logo {
    display: flex;
    justify-content: center;
    align-items: center;
}

#site-nav .nav-wrapper .site-logo img {
    width: 125px;
}

#site-nav .nav-wrapper ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

#site-nav .nav-wrapper ul.main-menu {
    display: flex;
    align-items: center;
    gap: 15px;
}

#site-nav .nav-wrapper ul.main-menu li a {
    display: grid;
    align-items: center;
    justify-content: center;
    grid-template-columns: auto auto;
    transition: scale 250ms ease-in-out;
}

#site-nav .nav-wrapper ul.main-menu li a, #site-nav .nav-wrapper ul.main-menu li a:visited, #site-nav .nav-wrapper ul.main-menu li a:active, #site-nav .nav-wrapper ul.main-menu li a:hover {
    position: relative;
    z-index: 999;
    text-decoration: none;
    color: #444;
    font-size: 20px;
}

#site-nav .nav-wrapper ul.main-menu li a:after {
    display: block;
    grid-row: 2;
    grid-column: 1/3;
    content: "";
    border-bottom: solid 3px var(--color-accent);
    transform: scaleX(0);
    transition: transform 250ms ease-in-out;
}

#site-nav .nav-wrapper ul.main-menu li a.active:after {
    transform: scaleX(1);
}

#site-nav .nav-wrapper ul.main-menu li a .sub-menu-indicator {
    display: block;
    margin-left: 5px;
    margin-bottom: -5px;
}

#site-nav .nav-wrapper ul.main-menu li a .sub-menu-arrow {
    margin-right: 7.5px;
}

#site-nav .nav-wrapper ul.main-menu li div.sub-menu-wrapper {
    transition: visibility 0.3s ease-in-out;
    visibility: collapse;
}

#site-nav .nav-wrapper ul.main-menu li div.sub-menu-wrapper.sub-menu-wrapper-visible {
    visibility: visible;
}

#site-nav .nav-wrapper ul.main-menu li div.sub-menu-wrapper.sub-menu-wrapper-visible ul.sub-menu {
    margin-top: 0;
    opacity: 1;
}

#site-nav .nav-wrapper ul.main-menu li div.sub-menu-wrapper .sub-menu-placeholder {
    display: none;
}

#site-nav .nav-wrapper ul.main-menu li div.sub-menu-wrapper ul.sub-menu {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 5px;
    position: absolute;
    transition: margin 0.3s ease-in-out, opacity 0.3s ease-in-out;
    margin-top: 10px;
    opacity: 0;
}

#site-nav .nav-wrapper ul.main-menu:first-child {
    justify-content: flex-start;
}

#site-nav .nav-wrapper ul.main-menu:last-child {
    justify-content: flex-end;
}

#site-nav.allow-selection .nav-wrapper ul.main-menu li a:active {
    scale: 0.9;
}

#site-nav.allow-selection .nav-wrapper ul.main-menu li a:hover:after {
    transform: scaleX(1);
}

#site-nav.allow-selection .nav-wrapper ul.main-menu li a.from-right:after {
    transform-origin: 100% 50%;
}

#site-nav.allow-selection .nav-wrapper ul.main-menu li a.from-left:after {
    transform-origin: 0 50%;
}

@media (min-width: 768px) {
    #site-nav .mobile-menu-toggle {
        display: none;
    }

    #site-nav .nav-wrapper ul.main-menu li div.sub-menu-wrapper ul.sub-menu {
        background-color: var(--color-background-accent);
        padding: 5px 15px;
        border-radius: 0 0 var(--border-radius) var(--border-radius);
        box-shadow: var(--box-shadow);
    }
}

@media (max-width: 767px) {
    /* ============================= */
    /* =           START           = */
    /* = MOBILE MENU DEFAULT STYLE = */
    /* ============================= */
    #site-nav {
        padding: 15px 0;
    }

    #site-nav .nav-wrapper .site-logo img {
        width: 100%;
        max-width: 125px;
        height: auto;
        min-width: 75px;
    }

    #site-nav::before {
        position: absolute;
        content: "";
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
        background: var(--color-background-accent);
        background: linear-gradient(180deg, var(--color-background-accent) 0%, var(--color-accent-light) 50%, var(--color-background-accent) 100%);
        transition: opacity 0s ease-in-out, visibility 0s ease-in-out;
        opacity: 0;
        visibility: collapse;
    }

    #site-nav:not(.mobile-menu-open) .nav-wrapper {
        grid-template-columns: minmax(60px, 1fr) 1fr minmax(60px, 1fr);
        overflow: hidden;
    }

    #site-nav:not(.mobile-menu-open) .nav-wrapper .site-logo {
        grid-column: 2;
        transition: margin 0s ease-in-out;
    }

    #site-nav:not(.mobile-menu-open) .nav-wrapper ul.main-menu {
        display: none;
    }

    /* ============================= */
    /* =            END            = */
    /* = MOBILE MENU DEFAULT STYLE = */
    /* ============================= */
    /* ============================ */
    /* =           START          = */
    /* = MOBILE MENU OPENED STYLE = */
    /* ============================ */
    #site-nav.mobile-menu-open {
        position: fixed;
        z-index: 1999;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }

    #site-nav.mobile-menu-open::before {
        transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
        opacity: 1;
        visibility: visible;
    }

    #site-nav.mobile-menu-open .nav-wrapper {
        display: flex;
        flex-direction: column;
    }

    #site-nav.mobile-menu-open .nav-wrapper .site-logo {
        order: 1;
        transition: margin 0.3s ease-in-out;
        margin-bottom: 15px;
    }

    #site-nav.mobile-menu-open .nav-wrapper ul.main-menu {
        flex-direction: column;
    }

    #site-nav.mobile-menu-open .nav-wrapper ul.main-menu:first-child {
        order: 2;
        margin-bottom: 15px;
    }

    #site-nav.mobile-menu-open .nav-wrapper ul.main-menu:last-child {
        order: 3;
    }

    #site-nav.mobile-menu-open .nav-wrapper ul.main-menu li {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    #site-nav.mobile-menu-open .nav-wrapper ul.main-menu li div.sub-menu-wrapper {
        display: none;
        border-top: 2px solid var(--color-accent);
        border-bottom: 2px solid var(--color-accent);
        overflow: hidden;
    }

    #site-nav.mobile-menu-open .nav-wrapper ul.main-menu li div.sub-menu-wrapper.sub-menu-wrapper-visible {
        display: block;
        animation: slide-in-submenu 0.5s ease-in-out;
        margin-top: 5px;
        padding: 5px 0;
    }

    #site-nav.mobile-menu-open .nav-wrapper ul.main-menu li div.sub-menu-wrapper ul.sub-menu {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: initial;
        margin: 0 15px;
    }

    #site-nav.mobile-menu-open .nav-wrapper ul.main-menu li div.sub-menu-wrapper ul.sub-menu .sub-menu-arrow {
        display: none;
    }

    /* ============================ */
    /* =            END           = */
    /* = MOBILE MENU OPENED STYLE = */
    /* ============================ */
    /* ============================ */
    /* =           START          = */
    /* = MOBILE MENU BUTTON STYLE = */
    /* ============================ */
    #site-nav .mobile-menu-toggle {
        width: 30px;
        height: 30px;
        position: absolute;
        left: 15px;
        margin: 0;
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: 0.5s ease-in-out;
        -moz-transition: 0.5s ease-in-out;
        -o-transition: 0.5s ease-in-out;
        transition: 0.5s ease-in-out;
        cursor: pointer;
    }

    /* Default style for all menu button bars */
    #site-nav .mobile-menu-toggle span {
        display: block;
        position: absolute;
        height: 7px;
        width: 100%;
        background: var(--color-accent);
        border-radius: 9px;
        opacity: 1;
        left: 0;
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: 0.25s ease-in-out;
        -moz-transition: 0.25s ease-in-out;
        -o-transition: 0.25s ease-in-out;
        transition: 0.25s ease-in-out;
        -webkit-transform-origin: left center;
        -moz-transform-origin: left center;
        -o-transform-origin: left center;
        transform-origin: left center;
    }

    /* Default style for top menu button bar */
    #site-nav .mobile-menu-toggle span:nth-child(1) {
        top: 0;
    }

    /* Default style for middle menu button bar */
    #site-nav .mobile-menu-toggle span:nth-child(2) {
        top: 11.5px;
    }

    /* Default style for bottom menu button bar */
    #site-nav .mobile-menu-toggle span:nth-child(3) {
        top: 23px;
    }

    /* Opened style for all menu button bars */
    #site-nav .mobile-menu-toggle.mobile-menu-open span:nth-child(odd) {
        width: 141%;
    }

    /* Opened style for top menu button bar */
    #site-nav .mobile-menu-toggle.mobile-menu-open span:nth-child(1) {
        top: -3px;
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        transform: rotate(45deg);
    }

    /* Opened style for middle menu button bar */
    #site-nav .mobile-menu-toggle.mobile-menu-open span:nth-child(2) {
        width: 0;
        opacity: 0;
    }

    /* Opened style for bottom menu button bar */
    #site-nav .mobile-menu-toggle.mobile-menu-open span:nth-child(3) {
        top: 27px;
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        transform: rotate(-45deg);
    }

    /* ============================ */
    /* =            END           = */
    /* = MOBILE MENU BUTTON STYLE = */
    /* ============================ */
}

/* Slide-in animation */
@keyframes slide-in-submenu {
    0% {
        display: none;
        padding: 0;
        max-height: 0;
    }
    0.1% {
        display: block;
    }
    50% {
        padding: 5px 0;
    }
    100% {
        max-height: 100vh;
    }
}
