.info-item {
    margin-bottom: 5px;
}

.info-item:last-child {
    margin-bottom: 0;
}

.info-label {
    font-weight: bold;
}
